<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item">
        <a href="/admin/question/lists">Quản lý câu hỏi </a>
      </li>

      <li class="breadcrumb-item">Hỏi đáp</li>
    </ol>

    <div v-if="is_load">
      <SkeletonTheme>
        <Skeleton height="100px" v-for="k in 10" :key="k"></Skeleton>
      </SkeletonTheme>
    </div>

    <form method="post" @submit.prevent="mySubmit" id="sendform" v-else>
      <input type="hidden" :value="from.id" name="id" />
      <div class="container">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#home"
              >Tiếng Việt</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#menu1">English</a>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div id="home" class="container tab-pane active">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <strong>Thông tin chung </strong>
                    <small>Form</small>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name"
                            >Tên <span class="text-danger">*</span></label
                          >
                          <input
                            class="form-control"
                            name="name"
                            type="text"
                            v-model="from.name"
                          />
                          <p class="text-danger">{{ error.name }}</p>
                        </div>
                      </div>
                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="email">Email</label>
                          <input
                            class="form-control"
                            name="email"
                            type="text"
                            v-model="from.email"
                          />
                        </div>
                      </div>
                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="phone">Số điện thoại</label>
                          <input
                            class="form-control"
                            name="phone"
                            type="text"
                            v-model="from.phone"
                          />
                        </div>
                      </div>

                      <div class="col-sm-10">
                        <div class="form-group row">
                          <div class="col-sm-3">
                            <label for="name"> Hiện thị trang chủ </label>
                          </div>
                          <div class="col-sm-9">
                            <div class="radio">
                              <label>
                                <input
                                  class=""
                                  name="isHome"
                                  type="radio"
                                  value="1"
                                  v-model="from.isHome"
                                />
                                Hiện
                              </label>
                            </div>
                            <div class="radio">
                              <label>
                                <input
                                  class=""
                                  name="isHome"
                                  type="radio"
                                  value="2"
                                  v-model="from.isHome"
                                />
                                Không hiện
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="orderBy">Sắp xếp</label>
                          <input
                            class="form-control"
                            name="orderBy"
                            type="number"
                            v-model="from.orderBy"
                          />
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="questionName">
                            Câu hỏi <span class="text-danger">*</span></label
                          >
                          <ckeditor
                            :config="editorConfig_ques"
                            v-model="questionName"
                            @namespaceloaded="onNamespaceLoaded"
                          ></ckeditor>
                          <p class="text-danger">{{ error.questionName }}</p>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="awesome"> Trả lời </label>
                          <ckeditor
                            :config="editorConfig_asome"
                            v-model="awesome"
                            @namespaceloaded="onNamespaceLoaded"
                          ></ckeditor>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="menu1" class="container tab-pane fade">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <strong>Page </strong>
                    <small>Form</small>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name_EN"
                            >Tên <span class="text-danger">*</span></label
                          >
                          <input
                            class="form-control"
                            name="name_EN"
                            type="text"
                            v-model="from.name_EN"
                          />
                          <p class="text-danger">{{ error.name_EN }}</p>
                        </div>
                      </div>
                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="email_en">Email</label>
                          <input
                            class="form-control"
                            name="email_en"
                            type="text"
                            v-model="from.email_en"
                          />
                        </div>
                      </div>
                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="phone_en">Phone</label>
                          <input
                            class="form-control"
                            name="phone_en"
                            type="text"
                            v-model="from.phone_en"
                          />
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="questionName_EN">
                            Question <span class="text-danger">*</span></label
                          >
                          <ckeditor
                            :config="editorConfig_ques_EN"
                            v-model="questionName_EN"
                            @namespaceloaded="onNamespaceLoaded"
                          ></ckeditor>

                          <p class="text-danger">{{ error.questionName_EN }}</p>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="awesome_EN"> Awesome </label>
                          <ckeditor
                            :config="editorConfig_asome_EN"
                            v-model="awesome_EN"
                            @namespaceloaded="onNamespaceLoaded"
                          ></ckeditor>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-footer"
            style="width: 90%; position: fixed; bottom: 0"
          >
            <button
              type="submit"
              class="btn btn-sm btn-primary"
              v-if="!is_load"
            >
              <i class="fa fa-save"></i> Save
            </button>
            <h5 v-else>Loading...</h5>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
export default {
  data() {
    return {
      from: {},
      ishome: 1,
      id: this.$route.params.id,
      is_load: false,
      questionName: "",
      questionName_EN: "",
      awesome: "",
      awesome_EN: "",
      error: {
        name: "",
        name_EN: "",
        questionName: "",
        questionName_EN: "",
      },
      editorConfig_ques: window.editorConfig_ques,
      editorConfig_ques_EN: window.editorConfig_ques_EN,
      editorConfig_asome: window.editorConfig_asome,
      editorConfig_asome_EN: window.editorConfig_asome_EN,
    };
  },
  components: {
    Skeleton,
    SkeletonTheme,
  },
  mounted() {
    this.is_load = true;
    axios
      .get(
        window.my_api + "api/question/get-question-by-id?questionId=" + this.id,
        {
              headers: window.my_header
            }
      )
      .then((res) => {
        if (res.status == 200) {
          this.from = res.data.data;
          this.questionName = res.data.data.questionName;
          this.awesome = res.data.data.awesome;
          this.questionName_EN = res.data.data.questionName_EN;
          this.awesome_EN = res.data.data.awesome_EN;
          this.is_load = false;
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      })
      .catch(() => {
        alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
      });
  },

  methods: {
    onNamespaceLoaded( CKEDITOR ) {
        CKEDITOR.plugins.addExternal( 'video', '/layout/ckeditor/video/plugin.js' );
    },
    alertError() {
      let tt = "";
      for (let i = 0; i < Object.values(this.error).length; i++) {
        if (Object.values(this.error)[i]) {
          tt =
            tt +
            "<ul style='text-align:left;'><li>" +
            Object.values(this.error)[i] +
            "</li></ul>";
        }
      }
      return tt;
    },
    mySubmit: function () {
      let myForm = document.getElementById("sendform");
      let formData = new FormData(myForm);
      for (var pair of formData) {
        if (pair[0] == "name") {
          if (pair[1] == "") {
            this.error.name = "Vui lòng nhập tên.";
          } else {
            this.error.name = "";
          }
        }
        if (pair[0] == "name_EN") {
          if (pair[1] == "") {
            this.error.name_EN = "Vui lòng nhập tên tiếng anh";
          } else {
            this.error.name_EN = "";
          }
        }
      }
      if (this.questionName == "") {
        this.error.questionName = "Vui lòng nhập câu hỏi.";
      } else {
        this.error.questionName = "";
      }
      if (this.questionName_EN == "") {
        this.error.questionName_EN = "Vui lòng nhập câu hỏi tiếng anh";
      } else {
        this.error.questionName_EN = "";
      }
      if (
        this.error.name == "" &&
        this.error.name_EN == "" &&
        this.error.questionName == "" &&
        this.error.questionName_EN == ""
      ) {
        this.is_load = true;
        formData.append("questionName", this.questionName);
        formData.append("awesome", this.awesome);
        formData.append("questionName_EN", this.questionName_EN);
        formData.append("awesome_EN", this.awesome_EN);
        axios
          .post(
            window.my_api + "api/question/create-or-update-question",
            formData,
            {
              headers: window.my_header
            }
          )
          .then((res) => {
            if (res.status == 200) {
              alert("Cập nhật thành công.")
              this.is_load=false;
              
            } else {
              alert("Cập nhật không thành công, vui lòng liên hệ admin");
            }
            this.is_load = false;
          });
      } else {
        console.log(this.alertError());

alert("Vui lòng nhập đầy đủ");
      }
    },
  },
};
</script>